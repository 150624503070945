import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumb: React.FC = () => {
  const location = useLocation();
  
  // Split the location path and filter out empty strings
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <nav aria-label="breadcrumb">
      <ol className="flex space-x-2 text-gray-600 pb-2">
        <li>
          <Link to="/" className="text-blue-600 hover:underline">Home</Link>
        </li>
        {pathnames.map((value, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return isLast ? (
            <li key={index} className="text-gray-500">
              <span className="mx-2">/</span>
              {decodeURIComponent(value.replace(/-/g, ' '))}
            </li>
          ) : (
            <li key={index} className="flex items-center">
              <span className="mx-2">/</span>
              <Link to={routeTo} className="text-blue-600 hover:underline">
                {decodeURIComponent(value.replace(/-/g, ' '))}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
