import React, { useEffect } from 'react';
import img1 from '../../assets/coursesimage/img1.webp';
import img2 from '../../assets/coursesimage/1mg2.webp';
import { Link } from 'react-router-dom';

const AICourses: React.FC = () => {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const courses = [
    {
      id: '1',
      title: 'AI and Machine Learning',
      description: 'Learn the fundamentals of AI and Machine Learning, including algorithms, data processing, and model creation. Perfect for beginners starting their AI journey.',
      image: { url: img1 },
      enrolledCount: 1200,
      duration: 120,
      price: 50,
      link: 'https://example.com/ai-course-1',
    },
    {
      id: '2',
      title: 'Prompt Engineering for AI',
      description: 'Master the art of prompt engineering in AI applications. Learn to craft effective prompts for optimal responses in AI-powered systems. Perfect for beginners starting their AI journey.',
      image: { url: img2 },
      enrolledCount: 950,
      duration: 90,
      price: 35,
      link: 'https://example.com/ai-course-2',
    },
    {
      id: '3',
      title: 'Introduction to Generative AI',
      description: 'Explore generative AI models, including neural networks and GANs, to create unique digital content. Ideal for those curious about AI creativity.',
      image: { url: img1 },
      enrolledCount: 800,
      duration: 150,
      price: 60,
      link: 'https://example.com/ai-course-3',
    },
  ];

  return (
    <div className="container mx-auto pt-20 px-6">
      <nav aria-label="breadcrumb">
        <ol className="flex space-x-2 text-gray-600">
          <li>
            <Link to="/" className="text-blue-600 hover:underline">Home</Link>
          </li>
          <li>
            <span className="mx-2">/</span>
            <span className="text-gray-500">Popular Topics</span>
          </li>
        </ol>
      </nav>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {courses.map((course) => (
          <div
            key={course.id}
            className="bg-white rounded-3xl w-full lg:w-80 h-[25rem] lg:h-[90%] mt-3 mb-6 ml-2 shadow-lg overflow-hidden border-2 border-gray-200 relative cursor-pointer transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl hover:z-10"
            onClick={() => window.open(course.link, '_blank')} // Open enroll link when card is clicked
          >
            <img
              src={course.image.url || 'https://via.placeholder.com/150'}
              alt={course.title}
              className="w-full h-56 object-cover rounded"
            />
            <h4 className="text-lg font-semibold text-left text-gray-800 p-2">
              {course.title}
            </h4>
            <div className="flex items-center justify-between text-gray-600 p-2 w-full">
              <div className="flex items-center">
                <img
                  src="https://img.icons8.com/ios-filled/24/050ADF/user-group-man-man.png"
                  alt="Users"
                  className="mr-2"
                />
                <span>{course.enrolledCount || 'N/A'} users</span>
              </div>
              <div className="flex items-center">
                <img
                  src="https://img.icons8.com/ios-filled/24/050ADF/time.png"
                  alt="Duration"
                  className="mr-2"
                />
                <span>{course.duration || 'N/A'} min</span>
              </div>
            </div>
            <hr className="border-t border-blue-400 " />
            <p className="text-gray-600 text-left p-2">
              {course.description || 'No description available.'}
            </p>
            <div className="flex justify-between items-center p-2 w-full">
              <span className="w-14 font-semibold text-white rounded-full pl-2" style={{ backgroundColor: '#050ADF' }}>
                ${course.price || 'Free'}
              </span>
              <button
                onClick={() => window.open(course.link, '_blank')}
                className="border-2 rounded-full w-14 font-semibold"
                style={{ color: '#050ADF', borderColor: '#050ADF' }}
              >
                Enroll
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AICourses;
