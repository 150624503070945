import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Swiper as SwiperClass } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { useNavigate } from 'react-router-dom';
import arrow from '../assets/Course Slection Icon/Arrrow-01.svg';
import stu from '../assets/Course Slection Icon/Person-01.svg';
import less from '../assets/Course Slection Icon/Lesson-01.svg';
import leftCarrow from '../assets/craousel/Untitled-design.svg';
import rightCarrow from '../assets/craousel/Circle Arrow Icon-01.svg';
import { FiClock } from 'react-icons/fi';


// Interfaces for Course, Category, and Subcategory
interface Course {
  _id: string;
  title: string;
  image: {
    url: string;
  };
  category: {
    _id: string;
    name: string;
  };
  subCategory: {
    _id: string;
    name: string;
  }[];
  price: number;
  enrolledCount: number;
  noOfLessions:number;
  duration:number;
}

interface Category {
  _id: string;
  name: string;
  active: boolean;
  createDate: string;
  modifiedDate: string;
}

interface Subcategory {
  _id: string;
  name: string;
  active: boolean;
  createdDate: string;
  modifiedDate: string;
}

const TrendingCourse: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);
  const [courses, setCourses] = useState<Course[]>([]);
  const [filteredCourses, setFilteredCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingSubcategories, setLoadingSubcategories] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const swiperRef = useRef<SwiperClass | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get<Category[]>('https://spark-mind.vercel.app/api/category/getall/');
        setCategories(response.data);
        if (response.data.length > 0) {
          setActiveTab(response.data[0]._id);
          setSelectedCategoryName(response.data[0].name);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching main categories:', error);
        setLoading(false);
      }
    };
    fetchCategories();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchCoursesForCategory = async (categoryId: string) => {
    setLoadingCourses(true);
    try {
      const response = await axios.get<Course[]>(`https://spark-mind.vercel.app/api/courses?category=${categoryId}`);
      const categoryCourses = response.data.filter((course: Course) => course.category._id === categoryId);
      setCourses(categoryCourses);
      setFilteredCourses(categoryCourses); // Set filteredCourses initially to all courses for the selected category
      setLoadingCourses(false);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setLoadingCourses(false);
    }
  };
  
  useEffect(() => {
    const fetchSubcategories = async (categoryId: string) => {
      setLoadingSubcategories(true);
      try {
        const response = await axios.get<Subcategory[]>(`https://spark-mind.vercel.app/api/subcategories/get?category=${categoryId}`);
        setSubcategories(response.data);
        setSelectedSubcategory(null);
        setLoadingSubcategories(false);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
        setLoadingSubcategories(false);
      }
    };

    if (activeTab) {
      fetchSubcategories(activeTab);
      fetchCoursesForCategory(activeTab);
    }
  }, [activeTab]);
  

  const handleSubcategorySelect = (subcategoryId: string) => {
    setSelectedSubcategory(subcategoryId);
    
    // Check if courses exist for the selected subcategory
    const filtered = courses.filter((course: Course) =>
      course.subCategory.some((sub) => sub._id === subcategoryId) // Check if subCategory contains the selected subcategory ID
    );

    setFilteredCourses(filtered.length > 0 ? filtered : courses); // If no courses found, show all courses for the category
  };

  const handleCategorySelect = (categoryId: string, categoryName: string) => {
    setActiveTab(categoryId);
    setSelectedCategoryName(categoryName);
    setSelectedSubcategory(null);
    fetchCoursesForCategory(categoryId);
  };

  const navigate = useNavigate();

  const handleCourseClick = (courseId: string) => {
    navigate(`/courses/${courseId}`);
  };

  const handleHover = () => {
    if (swiperRef.current) swiperRef.current.autoplay.stop();
  };

  const handleMouseLeave = () => {
    if (swiperRef.current) swiperRef.current.autoplay.start();
  };

  const handleViewMore = () => {
    navigate('/categories');
  };

  const SkeletonLoader: React.FC = () => (
    <div className="animate-pulse bg-white rounded-3xl w-80 shadow-lg overflow-hidden border-2 border-gray-200 relative p-4 space-y-4">
      <div className="bg-gray-300 h-48 w-full rounded-md"></div>
      <div className="flex justify-between items-center">
        <div className="h-6 bg-gray-300 rounded w-1/3"></div>
        <div className="h-6 bg-gray-300 rounded w-1/4"></div>
      </div>
      <div className="h-4 bg-gray-300 rounded w-3/4"></div>
      <div className="flex justify-between">
        <div className="h-4 bg-gray-300 rounded w-1/3"></div>
        <div className="h-4 bg-gray-300 rounded w-1/3"></div>
      </div>
      <div className="flex justify-end pt-3">
        <div className="h-6 bg-gray-300 rounded w-1/6"></div>
      </div>
    </div>
  );

  const SubcategorySkeletonLoader: React.FC = () => (
    <div className="flex space-x-4">
      {Array(5).fill(0).map((_, index) => (
        <div key={index} className="bg-gray-300 h-10 w-24 rounded-full"></div>
      ))}
    </div>
  );

  return (
    <section className="w-full">

      <div className="flex justify-start pl-4 lg:pl-28 mt-4">
        <h2 className="text-2xl font-semibold text-center mb-4 border-2 border-black rounded-3xl px-6 py-2">Trending on SparkMinds</h2>
      </div>
      <div className="mx-auto bg-gray-100 p-8 lg:p-16 flex justify-around overflow-hidden flex-col ">
        <div className="cardContent bg-white rounded-2xl w-full lg:w-11/12 mx-auto ">

          <div className="h-24 bg-gray-200 rounded-t-2xl flex justify-start lg:justify-between border-b-2 overflow-hidden ">
            <div className="w-full lg:hidden">
              <select
                className="w-full p-4 bg-white border border-gray-300 rounded-md "
                value={activeTab || ''}
                onChange={(e) => {
                  const selectedCategory = categories.find(cat => cat._id === e.target.value);
                  if (selectedCategory) handleCategorySelect(selectedCategory._id, selectedCategory.name);
                }}
              >
                {categories.map((category: Category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden lg:flex justify-start w-full">
              {categories.map((category: Category) => (
                <button
                  key={category._id}
                  className={`rounded-tl-2xl w-44 px-4 py-4 font-semibold hover:bg-gray-300  ${
                    activeTab === category._id ? 'text-white' : 'text-gray-700'
                  } ${
                    activeTab === category._id ? 'bg-[#00A8FF]' : ''
                  }`}
                  // style={{
                  //   backgroundColor: activeTab === category._id ? '#00A8FF' : 'transparent',
                    
                  // }}
                  onClick={() => handleCategorySelect(category._id, category.name)}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>

          <div className="mt-4 p-2 relative">
            <div className="pl-10">
              {loadingSubcategories ? (
                <SubcategorySkeletonLoader />
              ) : subcategories.length > 0 ? (
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1.5}
                  breakpoints={{
                    768: {
                      slidesPerView: 6,
                      spaceBetween: 10,
                    },
                  }}
                  navigation={{
                    nextEl: '.swiper-button-next-sub',
                    prevEl: '.swiper-button-prev-sub',
                  }}
                  modules={[Navigation]}
                >
                  {subcategories.map((subcategory: Subcategory) => (
                    <SwiperSlide key={subcategory._id}>
                      <button
                        className={`border-2 border-black px-3 py-1 rounded-full text-black hover:bg-gray-200`}
                        style={{
                          backgroundColor: selectedSubcategory === subcategory._id ? '#00A8FF' : 'transparent',
                        }}
                        onClick={() => handleSubcategorySelect(subcategory._id)}
                      >
                        {subcategory.name}
                      </button>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <p>No subcategories available.</p>
              )}
            </div>

            <div className="swiper-button-prev-sub hidden lg:flex absolute left-2 top-1/2 transform -translate-y-1/2 z-10 bg-white border border-gray-300 shadow-lg w-auto h-8 rounded-full flex justify-center items-center cursor-pointer transition duration-300">
              <button className="custom-prev text-white rounded-full">
                <img src={leftCarrow} alt="" className="w-8 h-8" />
              </button>
            </div>
            <div className="swiper-button-next-sub hidden lg:flex absolute right-2 top-1/2 transform -translate-y-1/2 z-10 bg-white border border-gray-300 shadow-lg w-auto h-8 rounded-full flex justify-center items-center cursor-pointer transition duration-300">
              <button className="custom-next rounded-full">
                <img src={rightCarrow} alt="" className="w-8 h-8 bg-black rounded-full" />
              </button>
            </div>
          </div>

          <div className="mt-4 mb-14 p-1 lg:pl-16 lg:pr-16 flex relative">
            {loadingCourses ? (
              <div className="grid grid-cols-4 gap-4">
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
              </div>
            ) : filteredCourses.length > 0 ? (
              <Swiper
                spaceBetween={10}
                slidesPerView={isMobile ? 1.2 : filteredCourses.length < 2 ? 1 : filteredCourses.length < 3 ? 1.5 : 3}
                centeredSlides={filteredCourses.length === 1}
                loop={filteredCourses.length > 1}
                breakpoints={{
                  768: {
                    slidesPerView: filteredCourses.length < 3 ? filteredCourses.length : 3,
                    spaceBetween: filteredCourses.length < 3 ? 10 : 20,
                  },
                }}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
                autoplay={{ delay: 3000 }}
                modules={[Navigation, Autoplay]}
                onSwiper={(swiper: SwiperClass) => (swiperRef.current = swiper)}
              >
                {filteredCourses.map((course: Course) => (
                  <SwiperSlide key={course._id}>
                    <div
                      onClick={() => handleCourseClick(course._id)}
                      className="bg-white rounded-3xl w-full lg:w-80 h-[25rem] lg:h-[90%] mt-3 mb-6 ml-2 shadow-lg overflow-hidden border-2 border-gray-200 relative cursor-pointer transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl hover:z-10"
                      onMouseEnter={handleHover}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img src={course.image.url} alt={course.title} className="w-full h-32 lg:h-48 object-cover" />
                      <div className="p-4">
                        <div className="flex justify-between items-center rounded-lg">
                          <span className="text-lg font-semibold text-black bg-gray-300 px-2 py-1 rounded">{selectedCategoryName}</span>
                          <span className="text-orange-500 text-lg font-bold">{course.price}</span>
                        </div>
                        <div className="">
                          <h3 className="text-lg font-bold text-gray-800 mt-2">{course.title}</h3>
                        </div>
                        <div className="flex justify-between mt-2 text-sm text-gray-600"
                        >
                          <div className="flex items-center">
                            <img className="w-5 pr-1" src={less} alt="Lessons" />
                            {course.noOfLessions} Lessons
                          </div>
                          {course.duration ? 
                            <div className="flex items-center">
                            <FiClock className="ml-4 mr-2" />
                            <span>{course.duration} min</span>  
                            </div>
                            :
                            ''
                          }

                        </div>
                      </div>
                      <div className="flex justify-end pt-3 pr-6 mb-2 lg:mb-6 text-orange-500">
                        <button className="text-lg">
                          <img src={rightCarrow} alt="Next" className="w-6 h-6 bg-orange-500 rounded-full" />
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <p>No courses available for this category.</p>
            )}
          </div>
        </div>

        {/* View More Button */}
        <div className="text-center flex justify-around mt-6">
          <button onClick={handleViewMore} className="text-xl flex flex-col items-center p-4 font-semibold text-gray-800">
            View more
            <img src={arrow} alt="" className="w-7 bg-black rounded-full" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default TrendingCourse;
