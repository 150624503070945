import React, { useEffect, useState } from 'react';

interface Workshop {
  _id: string;
  link: string;
  images: { filename: string; url: string }[];
  active: boolean;
}

interface WorkshopSectionProps {
  onNoActiveWorkshops: () => void; // Callback to notify Home when no active workshops
}

const WorkshopSection: React.FC<WorkshopSectionProps> = ({ onNoActiveWorkshops }) => {
  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorkshops = async () => {
      try {
        const response = await fetch('https://spark-mind.vercel.app/api/workshop/');
        const data = await response.json();
        setWorkshops(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching workshops:', error);
        setLoading(false);
      }
    };

    fetchWorkshops();
  }, []);

  const activeWorkshops = workshops.filter((workshop) => workshop.active);

  // Call the prop function if there are no active workshops
  useEffect(() => {
    if (activeWorkshops.length === 0) {
      onNoActiveWorkshops();
    }
  }, [activeWorkshops, onNoActiveWorkshops]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (activeWorkshops.length === 0) {
    return null; // Don't show anything if no active workshops
  }

  return (
    <div className="container mx-auto p-6 py-8">
      <h2 className="text-4xl font-bold text-center mb-8 text-blue-700">Ongoing Workshops</h2>
      <div className="flex justify-start gap-7 flex-wrap">
        {activeWorkshops.map((workshop) => (
          <a
            key={workshop._id}
            href={workshop.link}
            target="_blank"
            rel="noopener noreferrer"
            className="relative max-w-md overflow-hidden cursor-pointer transform transition-transform 
              duration-500 ease-in-out hover:-translate-y-3 hover:scale-110"
          >
            {workshop.images.length > 0 && (
              <img
                src={workshop.images[0].url}
                alt="Workshop"
                className="rounded-lg shadow-lg object-cover w-96 h-[28rem]"
              />
            )}
          </a>
        ))}
      </div>
    </div>
  );
};

export default WorkshopSection;
