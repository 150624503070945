import React from 'react';
import { useNavigate } from 'react-router-dom';

// Import local icons
import aiIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/Computer Scinece-01.svg';
import cpIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/Cprogramming-01.svg';
import pythonIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/DevOps and Cloud-01.svg';
import mlIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/Machine Learning-01.svg';
import devopsIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/Probability-01.svg';
import supplyChainIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/Python-01.svg';
import probabilityIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/Statics-01.svg';
import csIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/Writing-01.svg';
import writingIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/Supply Chain-01.svg';
import statisticsIcon from '../assets/Popular Topics Icons-20240925T093743Z-001/Popular Topics Icons/ai-01.svg';

const topics = [
  { icon: statisticsIcon, path: '/courses/ai', name :"Artificial Intelligence" },
  { icon: cpIcon, path: '/courses/cpcourses', name : ' Computer Programming ' },
  { icon: supplyChainIcon , path: '/courses/python', name:'Python' },
  { icon: mlIcon, path: '/courses/ml', name:'Ai & Ml' },
  { icon: pythonIcon , path: '/courses/leadership', name :'Cloud Computing' },
  { icon: writingIcon , path: '/courses/supply-chain', name:'Supply-Chain' },
  { icon: devopsIcon , path: '/courses/probability', name:'Devops' },
  { icon: aiIcon, path: '/courses/computer-science', name:'Data Analytics' },
  { icon: csIcon, path: '/courses/writing', name:'Writing' },
  { icon: probabilityIcon, path: '/courses/statistics', name:'Probability' },
];

const PopularTopics: React.FC = () => {
  const navigate = useNavigate();

  const handleTopicClick = (path: string) => {
    navigate(path);
  };

  return (
    <section>
      <div className="container w-full mx-auto py-12 px-4 lg:px-12">
        <div className="flex justify-center lg:justify-start lg:pl-28 mb-8 mt-8">
          <h2 className="text-2xl sm:text-3xl font-semibold text-center mb-8 border-2 border-black rounded-3xl px-6 py-2">
            Popular Topics
          </h2>
        </div>
        
        {/* Topic Cards: Responsive Grid */}
        <div className="grid grid-cols-2 gap-6 lg:pl-28 lg:pr-28 sm:grid-cols-3 lg:grid-cols-5 justify-center">
          {topics.map((topic, index) => (
            <div
              key={index}
              className="border  border-orange-500 bg-white rounded-3xl w-full h-40 sm:h-48 p-4 transition-transform transform hover:-translate-y-2 hover:shadow-xl hover:border-transparent cursor-pointer flex flex-col items-center justify-center"
              onClick={() => handleTopicClick(topic.path)}
            >
              <div className="w-20 h-20 sm:w-28 sm:h-28 flex items-center justify-center">
                <img src={topic.icon} className="w-full h-full object-contain" alt={`icon-${index}`} />
              </div>
              <div className=""><span className="text-gray-600 font-bold">{topic.name}</span></div>
            </div>
          ))}
        </div>
        
        {/* Horizontal line at the bottom */}
        <div className="w-full mt-12 flex justify-around">
          <hr className="border-t-2 w-2/3 border-orange-500" />
        </div>
      </div>
    </section>
  );
};

export default PopularTopics;
