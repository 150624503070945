// src/App.tsx
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import SaleBanner from './components/SaleBanner'; // Import the SaleBanner
import Header from './components/Header'; // Import the Header
import Home from './pages/Home';
import Categories from './pages/Categories';
import Teacher from './pages/Teacher';
import './App.css';
import CourseSection from './components/TrendingCourse';
import CourseDetail from './components/CourseDetail';
import AICourses from './components/coursespage/AICourses';
import PythonCourses from './components/coursespage/PythonCourses';
import CpCourses from './components/coursespage/CpCourses';
import Breadcrumb from './components/Breadcrumb';

const App: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Breadcrumb/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/become-teacher" element={<Teacher />} />
          {/* <Route path="/courses" element={<CourseSection />} /> */}
          <Route path="/courses/:courseId" element={<CourseDetail />} />
          <Route path="/courses/ai" element={<AICourses />} />
          <Route path="/courses/python" element={<PythonCourses />} />
          <Route path="/courses/cpcourses" element={<CpCourses />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
