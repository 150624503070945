import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import sideface from '../assets/images/Screenshot (383).png'

const TestimonialCarousel: React.FC = () => {
  return (
    <section className=" py-16 px-4">
      <div className="container mx-auto flex flex-col lg:flex-row items-center">
      {/* Image Section */}
      <div className="relative w-full lg:w-1/2 max-w-sm mx-auto">
        <div className="absolute top-0 right-0 w-full h-full rounded-lg" ></div>
        <img
          src={sideface}
          alt="Virtual Classroom"
          className="relative z-10 w-full h-auto rounded-lg"
        />
      </div>
      <div className="w-full lg:w-1/2 mt-8 lg:mt-0 lg:ml-12">
        <h2 className="text-3xl lg:text-4xl font-bold mb-4 text-gray-800">About Us</h2>
        <p className="text-gray-700 text-lg leading-relaxed mb-4">
        At SparkMinds, we believe in shaping the future through innovative and accessible
education. Our platform connects learners worldwide with industry-leading
professionals, offering expertly designed courses in technology, business, and beyond.
Whether you're entering the workforce or transitioning careers, we provide the
knowledge and skills you need to succeed in an ever-evolving world. With a focus on
practical learning and professional growth, SparkMinds is your partner in unlocking new
opportunities and achieving your goals.
        </p>
      </div>
      </div>
    </section>
  );
};

export default TestimonialCarousel;
