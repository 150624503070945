import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/images/cut.png"; // Replace with the actual logo from the provided image

interface HeaderProps {
  bannerVisible: boolean;
}

const Header: React.FC<HeaderProps> = ({ bannerVisible }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false); // State to toggle search bar visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle menu visibility
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [searchResults, setSearchResults] = useState([]); // State for search results

  const dropdownRef = useRef<HTMLDivElement | null>(null); // Ref for the dropdown

  const toggleSearchBar = () => {
    setIsSearchOpen(!isSearchOpen); // Toggle search bar visibility
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle login/logout menu visibility
  };

  // Function to handle search input changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term); // Update search term state

    // Call the search API if the term is not empty
    if (term.length > 0) {
      fetch(`https://spark-mind.vercel.app/api/courses?search=${term}`)
        .then(response => response.json())
        .then(data => {
          setSearchResults(data); // Update search results
        })
        .catch(error => {
          console.error("Error fetching search results:", error);
          setSearchResults([]); // Clear results on error
        });
    } else {
      setSearchResults([]); // Clear results if the input is empty
    }
  };

  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setSearchResults([]); // Clear results when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <header
      className={`fixed left-0 w-full flex flex-col md:flex-row justify-between items-center py-2 bg-white shadow-md z-40`}
      style={{ top: bannerVisible ? '40px' : '0px', paddingTop: bannerVisible ? '60px' : '0px' }}
    >
      <div className="flex items-center w-full pl-2 pr-2 justify-between md:justify-start">
        {/* Logo Section */}
        <Link to="/" className="font-bold text-xl text-blue-700 flex items-center">
          <img
            src={logo}
            alt="Spark Minds"
            className="h-16 md:h-20 ml-4" // Adjusted logo size for mobile
          />
        </Link>

        {/* Search Bar Section */}
        <div className="pl-8 hidden md:flex flex-grow mx-4 max-w-xs sm:max-w-sm md:max-w-md w-full md:w-auto relative" ref={dropdownRef}>
          <div className="relative flex w-64">
            <input
              type="text"
              placeholder="What do you want to learn ?"
              className="w-full p-2 pl-4 rounded-full border-2 border-black focus:outline-none focus:ring-2 focus:ring-blue-300"
              style={{ fontSize: '16px', color: '#888' }}
              value={searchTerm}
              onChange={handleSearchChange} // Handle input changes
            />
            <button className="absolute right-3 top-2">
              <svg
                className="h-[1.6rem] w-[1.2rem] text-gray-400"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-4.35-4.35M17 10.5a6.5 6.5 0 10-13 0 6.5 6.5 0 0013 0z"
                />
              </svg>
            </button>
          </div>

          {/* Display Search Results Dropdown */}
          {searchResults.length > 0 && (
            <div className="absolute z-50 w-full bg-white shadow-lg mt-12 rounded-md max-h-60 overflow-y-auto">
              {searchResults.map((result: any) => (
                <Link to={`/courses/${result._id}`} key={result._id} className="block p-2 hover:bg-gray-100" onClick={() => setSearchResults([])}>
                  {result.title} {/* Assuming the course has a title field */}
                </Link>
              ))}
            </div>
          )}
        </div>

        {/* Mobile Search Icon */}
        <div className="flex items-center md:hidden">
          <span className="cursor-pointer ml-auto mr-4" onClick={toggleSearchBar}>
            <svg
              className="h-6 w-6 text-gray-600"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-4.35-4.35M17 10.5a6.5 6.5 0 10-13 0 6.5 6.5 0 0013 0z"
              />
            </svg>
          </span>

          {/* Menu Icon for Mobile */}
          <span className="cursor-pointer ml-2 mr-4" onClick={toggleMenu}>
            <svg
              className="h-6 w-6 text-gray-600"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </span>
        </div>
      </div>

      {/* Mobile Search Bar */}
      {isSearchOpen && (
        <div className="flex flex-col items-center w-full p-4 md:hidden relative" ref={dropdownRef}>
          <input
            type="text"
            placeholder="What do you want to learn?"
            className="w-full p-2 pl-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-300"
            style={{ fontSize: '16px', color: '#888' }}
            value={searchTerm}
            onChange={handleSearchChange} // Handle mobile search input changes
          />
          {/* Display Search Results Dropdown for Mobile */}
          {searchResults.length > 0 && (
            <div className="absolute z-50 w-full bg-white shadow-lg mt-12 rounded-md max-h-60 overflow-y-auto">
              {searchResults.map((result: any) => (
                <Link to={`/courses/${result._id}`} key={result._id} className="block p-2 hover:bg-gray-100" onClick={() => setSearchResults([])}>
                  {result.title}
                </Link>
              ))}
            </div>
          )}
        </div>
      )}

      {/* Login & Signup Buttons for desktop/tablet */}
      <div className="hidden w-64 md:flex items-center ml-auto space-x-4">
        <a
          href="https://learn.sparkminds.live/learn/account/signin"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 font-medium"
        >
          Sign in
        </a>
        <a
          href="https://learn.sparkminds.live/learn/account/signup"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-orange-500 text-white py-2 px-4 rounded-full"
        >
          Register Free
        </a>
      </div>

      {/* Login & Signup Menu for Mobile */}
      {isMenuOpen && (
        <div className="flex flex-col items-center space-y-2 md:hidden mt-4">
          <a
            href="https://learn.sparkminds.live/learn/account/signin"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 border rounded-full flex items-center"
            style={{ color: '#050ADF', borderColor: '#050ADF' }}
          >
            Sign in
          </a>
          <a
            href="https://learn.sparkminds.live/learn/account/signup"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 border border-orange-600 text-orange-900 rounded-full flex items-center"
            style={{ backgroundColor: '#f57c00', color: '#fff' }}
          >
            Register Free
          </a>
        </div>
      )}
    </header>
  );
};

export default Header;
