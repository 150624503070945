import React from 'react';
import { Link } from 'react-router-dom';
import designSVG from '../assets/images/Sahpes-01.svg'; // Update the correct path for the SVG

const Footer: React.FC = () => {
  return (
    <footer className="relative overflow-hidden">
      {/* Orange top bar */}
      <div
        style={{
          backgroundColor: '#FF8500',
          height: '45px',
          width: '100%',
        }}
      ></div>

      <div className="text-white p-4" 
        style={{ backgroundColor: '#05A8E6', paddingTop: '3.5rem', paddingBottom: '0.5rem' }}>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          {/* First Column */}
          <div className="space-y-4">
            <h4 className="text-lg font-semibold">SparkMinds</h4>
            <ul className="space-y-2">
              <li>Get the app</li>
              <li>About us</li>
              <li>Contact us</li>
            </ul>
          </div>

          {/* Second Column */}
          <div className="space-y-4">
            <ul className="space-y-2">
              <li><Link to="/become-teacher" className="hover:underline text-base">Careers</Link></li>
              <li>Blog</li>
              <li>Mentors</li>
              <li>SparkMinds Team</li>
            </ul>
          </div>

          {/* Third Column */}
          <div className="space-y-4">
            <ul className="space-y-2">
              <li>
                <a
                href="https://learn.sparkminds.live/learn/pages/terms-of-service.html"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline text-base">Terms</a></li>
              <li>
                <a
                href="https://learn.sparkminds.live/learn/pages/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline text-base">Privacy policy</a></li>
              <li>Sitemap</li>
              {/* <li>Accessibility statement</li>  */}
              <li>
                <a
                href="https://helpdesk.sparkminds.live/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline text-base">Help and Support </a></li>
            </ul>
          </div>
        </div>

        {/* Language Selector */}
        <div className="relative flex justify-center md:absolute md:right-16 md:top-14 mt-6 md:mt-0">
          <div className="h-8 border border-white px-4 py-2 rounded-full flex items-center space-x-2 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 2a10 10 0 100 20 10 10 0 000-20zM2.05 13h19.9M12 2v20" />
            </svg>
            <span>English</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 12l-6-6h12l-6 6z" clipRule="evenodd" />
            </svg>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="text-center pt-8">
          <a
            href="https://myrsv.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline text-base"
          >
            Copyright © 2024 RSV Infotech Pte Ltd SparkMinds. All Rights Reserved.
          </a>
        </div>

        {/* SVG Design Positioned at Bottom Right, Partially Visible */}
        {/* <img
          src={designSVG}
          alt="Footer Design"
          className="absolute"
          style={{
            width: '31%',
            height: 'auto',
            right: '-213px',
            bottom: '-181px',
            zIndex: 0,
            overflow: 'hidden',
            filter: 'invert(45%) sepia(94%) saturate(1449%) hue-rotate(165deg) brightness(82%) contrast(104%)', // Changes color to #009BEB
          }}
        /> */}
      </div>
    </footer>
  );
};

export default Footer;
