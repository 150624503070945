import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import img1 from '../assets/craousel/2.jpg';
import img2 from '../assets/craousel/3.jpg';
import img3 from '../assets/craousel/1.jpg';
import leftCarrow from '../assets/craousel/Untitled-design.svg';
import rightCarrow from '../assets/craousel/Circle Arrow Icon-01.svg';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';


const Carousel: React.FC = () => {
  return (
    <div className="relative w-full mt-[3rem] pt-2">
      <Swiper
        pagination={{
          type: 'bullets',
          clickable: true,
        }}
        navigation={{
          nextEl: '.custom-next',
          prevEl: '.custom-prev',
        }}
        autoplay={{
          delay: 3000, // Time in ms between slides
          disableOnInteraction: false, // Keeps autoplay running even after interaction
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="w-full"
      >
        <SwiperSlide>
          <div className="relative w-full">
            <img
              src={img3}
              alt="Slide 3"
              className="w-full h-[250px] sm:h-[350px] md:h-[450px] lg:h-[500px] object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-start">
              <div className="p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 text-left max-w-[90%] md:max-w-[80%]">
                <h2 className="text-white font-medium text-[1.2rem] sm:text-[1.5rem] md:text-[2rem] lg:text-[2.5rem] xl:text-[2.5rem] leading-tight">
                  Learn at your own pace
                </h2>
                <h1 className="font-bold text-[1.5rem] sm:text-[2.5rem] md:text-[3rem] lg:text-[4rem] xl:text-[3.8rem] text-white">
                  Join Our Courses and <br /> Enhance Your Skills
                </h1>
                <button className="mt-6 px-6 py-2 sm:px-8 sm:py-3 bg-[#00A8FF] text-white rounded-full text-[0.8rem] sm:text-[1rem] md:text-[1.2rem] lg:text-[1.4rem] font-semibold">
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="relative w-full">
            <img
              src={img1}
              alt="Slide 1"
              className="w-full h-[250px] sm:h-[350px] md:h-[450px] lg:h-[500px] object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-blue-800 bg-opacity-50 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 rounded-lg text-center max-w-[90%] md:max-w-[80%]">
                <h2 className="text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-2 text-white">
                  START LEARNING FROM HOME
                </h2>
                <h1 className="text-lg sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white">
                  Connect With Our Expert and Start Learning Today
                </h1>
                <button className="mt-4 px-4 sm:px-6 py-2 text-white rounded-full text-sm sm:text-md md:text-lg" style={{ backgroundColor: '#050ADF' }}>
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="relative w-full">
            <img
              src={img2}
              alt="Slide 2"
              className="w-full h-[250px] sm:h-[350px] md:h-[450px] lg:h-[500px] object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-blue-800 bg-opacity-50 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 rounded-lg text-center max-w-[90%] md:max-w-[80%]">
                <h2 className="text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-2 text-white">
                  LEARN AT YOUR OWN PACE
                </h2>
                <h1 className="text-lg sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white">
                  Join Our Courses and Enhance Your Skills
                </h1>
                <button className="mt-4 px-4 sm:px-6 py-2 text-white rounded-full text-sm sm:text-md md:text-lg" style={{ backgroundColor: '#050ADF' }}>
                  Join Now
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      {/* Custom next/prev buttons - hidden on mobile */}
      <div className="absolute inset-y-0 left-0 z-10 hidden sm:flex items-center justify-center">
        <button className="custom-prev text-white rounded-full">
          <img src={leftCarrow} alt="" className="w-8 h-8" />
        </button>
      </div>
      <div className="absolute inset-y-0 right-0 z-10 hidden sm:flex items-center justify-center">
        <button className="custom-next p-2 rounded-full">
          <img src={rightCarrow} alt="" className="w-8 h-8 bg-black rounded-full" />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
